/* globals GATSBY_MNAAS_PORTAL_MIDMARKET_BACKEND_BASE_URL, GATSBY_MNAAS_PORTAL_SDWAN_BACKEND_BASE_URL, GATSBY_MNAAS_PORTAL_AGS_AUTOMATION_BACKEND_BASE_URL, GATSBY_MNAAS_PORTAL_ACTIVATIONS_TSO_BASE_URL, GATSBY_MNAAS_PORTAL_ACTIVATIONS_CAMUNDA_BASE_URL, GATSBY_MNAAS_PORTAL_SMB_ACTIVATIONS_CAMUNDA_BASE_URL */

const tenants = {
  sdwan: "SDWAN",
  midMarket: "MIDMARKET",
  agsAutomation: "AGS_AUTOMATION",
  activationsTso: "ACTIVATIONS_TSO",
  activationsCamunda: "ACTIVATIONS_CAMUNDA",
  smbActivationsCamunda: "SMB_ACTIVATIONS_CAMUNDA",
  platform: "GATSBY_MNAAS_PORTAL_PLATFORM",
}

const pcfTenants = {
  oidcClientId: "OIDC_CLIENT_ID",
  oidcRedirectBaseUri: "OIDC_REDIRECT_BASE_URI",
  oidcIssuerUrl: "OIDC_ISSUER_URL",
}

const getPcfDetail = (pcfTenant) => {
  switch (pcfTenant) {
    case pcfTenants.oidcClientId:
      return OIDC_CLIENT_ID
    case pcfTenants.oidcRedirectBaseUri:
      return OIDC_REDIRECT_BASE_URI
    case pcfTenants.oidcIssuerUrl:
      return OIDC_ISSUER_URL
    default:
      return null
  }
}

const getBaseURL = (tenant) => {
  switch (tenant) {
    case tenants.midMarket:
      return GATSBY_MNAAS_PORTAL_MIDMARKET_BACKEND_BASE_URL
    case tenants.sdwan:
      return GATSBY_MNAAS_PORTAL_SDWAN_BACKEND_BASE_URL
    case tenants.agsAutomation:
      return GATSBY_MNAAS_PORTAL_AGS_AUTOMATION_BACKEND_BASE_URL
    case tenants.activationsTso:
      return GATSBY_MNAAS_PORTAL_ACTIVATIONS_TSO_BASE_URL
    case tenants.activationsCamunda:
      return GATSBY_MNAAS_PORTAL_ACTIVATIONS_CAMUNDA_BASE_URL
    case tenants.smbActivationsCamunda:
      return GATSBY_MNAAS_PORTAL_SMB_ACTIVATIONS_CAMUNDA_BASE_URL
    default:
      return null
  }
}

const getPlatform = () => {
  return GATSBY_MNAAS_PORTAL_PLATFORM
}

module.exports = {
  tenants,
  pcfTenants,
  getBaseURL,
  getPlatform,
  getPcfDetail,
}

import { UserManager, Log, WebStorageStateStore } from "oidc-client"
import envUtil from "./env-util"
import { navigate } from "gatsby"
import axios from "axios"
import { decodeToken, isExpired } from "react-jwt"

Log.logger = console
Log.level = Log.DEBUG

// const getSetting = () => {
var settings = {
  authority: `${envUtil.getPcfDetail(envUtil.pcfTenants.oidcIssuerUrl)}`,
  client_id: `${envUtil.getPcfDetail(envUtil.pcfTenants.oidcClientId)}`,
  redirect_uri: `${envUtil.getPcfDetail(
    envUtil.pcfTenants.oidcRedirectBaseUri
  )}/oidc/login`,
  scope: "openid profile",
  response_type: "id_token token",
  post_logout_redirect_uri: `${envUtil.getPcfDetail(
    envUtil.pcfTenants.oidcRedirectBaseUri
  )}`,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 14280,
  silent_redirect_uri: `${envUtil.getPcfDetail(
    envUtil.pcfTenants.oidcRedirectBaseUri
  )}/oidc/silent`,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: false,
  monitorSession: false,
  //client_authentication: "client_secret_basic",
  //userStore: new WebStorageStateStore({ store: localStorage }),
  userStore:
    typeof window !== "undefined"
      ? new WebStorageStateStore({ store: localStorage })
      : null,
  metadata: {
    userinfo_endpoint: `${envUtil.getPcfDetail(
      envUtil.pcfTenants.oidcIssuerUrl
    )}OidcUserInfo.aspx`,
    authorization_endpoint: `${envUtil.getPcfDetail(
      envUtil.pcfTenants.oidcIssuerUrl
    )}SecureAuth.aspx`,
    issuer: `${envUtil.getPcfDetail(envUtil.pcfTenants.oidcIssuerUrl)}`,
    jwks_uri: `${envUtil.getPcfDetail(
      envUtil.pcfTenants.oidcIssuerUrl
    )}.well-known/jwks`,
    end_session_endpoint: `${envUtil.getPcfDetail(
      envUtil.pcfTenants.oidcIssuerUrl
    )}OidcEndSession.aspx`,
    token_endpoint: `${envUtil.getPcfDetail(
      envUtil.pcfTenants.oidcIssuerUrl
    )}OidcToken.aspx`,
    check_session_iframe: `${envUtil.getPcfDetail(
      envUtil.pcfTenants.oidcIssuerUrl
    )}OidcCheckSession.aspx`,
  },
}
//return settings
//}

//const getUserManager = () => {
var userManager =
  typeof window !== "undefined" ? new UserManager(settings) : null

const tbtcArray = [
  "A001",
  "PYJ0",
  "JIEH",
  "DM8D",
  "XAJE",
  "EUNL",
  "QRE0",
  "QURZ",
  "AQ5W",
  "KRG7",
  "IUDK",
  "BMFS",
  "RH7R",
  "BCLE",
  "EH04",
  "WC1K",
  "ZGAW",
  "UY0M",
  "QOOT",
  "VYY9",
  "UR5B",
  "USU3",
  "OE4R",
  "RKIG",
  "RC0S",
  "WA0M",
  "HP4Y",
  "ICSG",
  "CG9X",
  "GORO",
]
const adaptS1Array = [
  "A001",
  "PYJ0",
  "AEJ7",
  "EK3T",
  "EJME",
  "EHKM",
  "EB4N",
  "AY3F",
  "AYPY",
  "F7JJ",
  "EM97",
  "AE3H",
  "E3W3",
  "FQN8",
  "OKWH",
  "EHEA",
  "ALFT",
  "AFWF",
]

if (userManager !== null) {
  userManager.events.addUserSignedIn(() => {
    console.log("User Signed In")
  })
  userManager.events.addUserSignedOut(function () {
    console.log("User Signed out")
  })
  userManager.events.addSilentRenewError(function (e) {
    console.log("silent renew error", e.message)
  })
  userManager.events.addAccessTokenExpiring(function () {
    console.log("token expiring")
  })
}
//return userManager
//}

export const login = () => {
  //const userManager = getUserManager()
  try {
    userManager.signinRedirect()
  } catch (err) {
    console.log(err)
  }
}

export const handleSilentRenew = async () => {
  //const userManager = getUserManager()
  try {
    const user = await userManager.signinSilentCallback()
    localStorage.setItem("cmiPortalToken", user.access_token)
    localStorage.setItem("cmiIdToken", user.id_token)
    console.log("Silent Renewing")
    const data = await getUserInfo(user.access_token)
    localStorage.setItem("cmiPortalUsername", data.sub)
    const pimsFixedDealerCode = decodeToken(user.id_token).pimsfixeddealercode
    const entitlementArray = []
    if (adaptS1Array.includes(pimsFixedDealerCode))
      entitlementArray.push("activations")
    if (tbtcArray.includes(pimsFixedDealerCode))
      entitlementArray.push("smb-activations")
    const entitlements = JSON.stringify(entitlementArray)
    localStorage.setItem("cmiPortalMemberOf", entitlements)
  } catch (err) {
    console.error(err)
  }
}

export const handleLoginResponse = async () => {
  //const userManager = getUserManager()
  try {
    const user = await userManager.signinRedirectCallback()
    localStorage.setItem("cmiPortalToken", user.access_token)
    localStorage.setItem("cmiIdToken", user.id_token)
    const data = await getUserInfo(user.access_token)
    localStorage.setItem("cmiPortalUsername", data.sub)
    const pimsFixedDealerCode = decodeToken(user.id_token).pimsfixeddealercode
    const entitlementArray = []
    if (adaptS1Array.includes(pimsFixedDealerCode))
      entitlementArray.push("activations")
    if (tbtcArray.includes(pimsFixedDealerCode))
      entitlementArray.push("smb-activations")
    const entitlements = JSON.stringify(entitlementArray)
    localStorage.setItem("cmiPortalMemberOf", entitlements)
    if (entitlementArray.at(0)) {
      navigate(`/${entitlementArray.at(0)}`)
    } else {
      throw new Error("User not authorized to view any application")
    }
  } catch (err) {
    console.error(err)
    await userManager.clearStaleState()
    localStorage.removeItem("cmiPortalToken")
    localStorage.removeItem("cmiPortalUsername")
    localStorage.removeItem("cmiIdToken")
    localStorage.removeItem("cmiPortalMemberOf")
    navigate("/")
  }
}

const getUserInfo = async (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: "POST",
      baseURL: `${envUtil.getPcfDetail(envUtil.pcfTenants.oidcIssuerUrl)}`,
      url: "OidcUserInfo.aspx",
      data: `access_token=${token}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        console.error(error)
        reject(error)
      })
  })

export const logout = () => {
  //const userManager = getUserManager()
  userManager.signoutRedirect().then(() => {
    localStorage.removeItem("cmiPortalToken")
    localStorage.removeItem("cmiPortalUsername")
    localStorage.removeItem("cmiIdToken")
    localStorage.removeItem("cmiPortalMemberOf")
    userManager.clearStaleState()
  })
}

export const getUserAccessToken = async () => {
  try {
    //const userManager = getUserManager()
    const user = await userManager.getUser()
    return user.access_token
  } catch (err) {
    console.log(err)
  }
}

export const getUser = () => {
  try {
    const access_token = localStorage.getItem("cmiPortalToken")
    if (access_token === null || isExpired(access_token)) {
      console.log("User is not found or has expired. Logging in user")
      login()
    }
  } catch (err) {
    console.log(err)
  }
}
